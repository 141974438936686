import React, { Component } from 'react'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import Layout from './layout'
import styled from 'styled-components'

import Seo from '../components/seo'

const PostContent = styled.div`
  background: white;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 3px 10px rgba(25, 17, 34, 0.1);

  @media screen and (min-width: 600px) {
    padding: 2.5rem 1rem;
  }

  > h1 {
    text-align: center;
    line-height: 1.2;
    margin-bottom: 0;
  }
  .type {
    text-align: center;
    color: #888;
  }
  .body,
  div,
  p {
    max-width: 680px;
    margin-left: auto;
    margin-right: auto;
  }
  .body {
    margin: 1rem auto;
  }
`

const BackBtn = styled.span`
  align-self: flex-end;
  display: inline-block;
  padding: 0.3rem 0.8rem;
  margin: 1rem 0;
  font-family: var(--sans-serif);
  font-size: 0.8rem;
  color: white;
  background: #1ba39c;
  border-radius: 3rem;
  transition: background 0.3s ease;

  &:hover {
    background: #097771;
    color: white;
  }
`

export default class postLayout extends Component {
  render() {
    const { markdownRemark } = this.props.data
    const { location } = this.props
    return (
      <>
        <Seo title={markdownRemark.frontmatter.title} />
        <Layout location={location}>
          <PostContent>
            <h1>{markdownRemark.frontmatter.title}</h1>
            <p className="type">{markdownRemark.frontmatter.type}</p>
            <img
              style={{
                display: 'block',
                width: '100%',
                maxWidth: '500px',
                margin: '0.5rem auto 1.5rem',
              }}
              src={markdownRemark.frontmatter.thumbnail}
              alt={markdownRemark.frontmatter.title}
            />
            {markdownRemark.frontmatter.link && (
              <p>
                <strong>
                  <a
                    href={markdownRemark.frontmatter.link}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    See this project online &rarr;
                  </a>
                </strong>
              </p>
            )}
            <div
              dangerouslySetInnerHTML={{
                __html: markdownRemark.html,
              }}
              className="body"
            />
          </PostContent>
          <Link to="/">
            <BackBtn>&larr; Back Home</BackBtn>
          </Link>
        </Layout>
      </>
    )
  }
}

export const query = graphql`
  query PortfolioPostQuery($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        slug
        thumbnail
        type
        role
        link
      }
    }
  }
`
